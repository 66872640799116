import React from 'react'
import { graphql } from 'gatsby'

// Ours
import stripTrailingSlash from '../utils/strip-trailing-slash'
import SEO from '../components/SEO'
import { BlockWrapper } from '../components/MainBlocks'
import ProjectsListing from '../components/ProjectsListing'
import CallToAction from '../components/CallToAction'

class NewsPage extends React.Component {
  constructor(props) {
    super(props)

    this.allPostEdges = []
    if (props.data.posts && props.data.inProgress) {
      this.allPostEdges = this.allPostEdges.concat(
        props.data.posts.edges,
        props.data.inProgress.edges
      )
    }

    this.allPostEdges = this.allPostEdges.sort(
      (d1, d2) =>
        new Date(d2.node.date).getTime() - new Date(d1.node.date).getTime()
    )
  }

  render() {
    const props = this.props
    let title = 'News'
    let slugPrefix = stripTrailingSlash(props.location.pathname)

    return (
      <div>
        <SEO title={title} />
        <h1 className="hide">{title}</h1>
        <BlockWrapper maxWidth={6} bg="transparent">
          <ProjectsListing
            layout="news"
            edges={this.allPostEdges}
            slugPrefix={slugPrefix}
          />
        </BlockWrapper>
        <div className="md-mt2">
          <CallToAction />
        </div>
      </div>
    )
  }
}

export default NewsPage

export const query = graphql`
  query NewsQuery {
    posts: allWpPost(
      filter: {
        slug: {
          nin: [
            "placeholder"
            "placeholder-link-post"
            "placeholder-video-post"
          ]
        }
      }
    ) {
      edges {
        node {
          title
          slug
          uri
          details {
            description
          }
          postFormats {
            nodes {
              name
            }
          }
          postFormatLink {
            link {
              target
              title
              url
            }
          }
          postFormatVideo {
            link {
              target
              title
              url
            }
          }
          categories {
            nodes {
              id
              name
              slug
            }
          }
          date
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  id
                  resize(width: 1280, height: 800) {
                    src
                  }
                  # fluid(maxWidth: 1280, maxHeight: 1280) {
                  #  ...GatsbyImageSharpFluid_withWebp_noBase64
                  # }
                }
              }
            }
          }
        }
      }
    }
    inProgress: allWpProject(
      filter: { projectDetails: { projectStatus: { eq: "in-progress" } } }
    ) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          title
          slug
          uri
          date
          details {
            description
          }
          projectMainContent {
            gallery {
              localFile {
                childImageSharp {
                  id
                  fluid(maxWidth: 1280) {
                    src
                    srcSet
                    # ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          projectDetails {
            projectRelatedexpertise {
              ... on WpExpertise {
                title
                # databaseId
              }
            }
            projectRelatedexpertiseOther {
              ... on WpProject_Projectdetails_projectRelatedexpertiseOther {
                postTitle
              }
            }
            projectStatus
            client
            location
            year
          }
        }
      }
    }
  }
`
